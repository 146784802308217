<template>
    <section class="dashboard container-fluid bg-white p-4" style="overflow-y: scroll;">
        <div class="row">
            <div class="col-12 mb-4">
                <span class="ge--description-md">
                    <b>Historial de análisis</b>
                </span>
            </div>

            <div class="col-12 mb-4">

                <ge-table :data="data_table" :allow_headers="allow_headers">

                    <template v-slot:studio_blood_creation="data">
                        <div @click="downloadPDF(data.row, 'blood')"
                            :class="`tag tag-${(data.value !== null) ? 'success cursor-pointer' : 'warning'}`">
                                <span :class="`ge--description-xs 
                                    text-${(data.value !== null) ? 'success' : 'warning'}`">
                                        <img :src="ico_download" v-if="data.value !== null">
                                        {{ (data.value !== null) ? 'Descargar' : 'Pendiente' }}
                                </span>
                        </div>
                    </template>

                    <template v-slot:order_id_genetic_file="data">
                        <div @click="downloadPDF(data.row, 'genetic')"
                            :class="`tag tag-${(data.value !== null) ? 'success cursor-pointer' : 'warning'}`">
                                <span :class="`ge--description-xs 
                                    text-${(data.value !== null) ? 'success' : 'warning'}`">
                                        <img :src="ico_download" v-if="data.value !== null">
                                        {{ (data.value !== null) ? 'Descargar' : 'Pendiente' }}
                                </span>
                        </div>
                    </template>

                    <template v-slot:creation="data">
                        <span class="ge--description-xs">
                            {{ $informativeDate(data.value) }}
                        </span>
                    </template>

                    <template v-slot:estatus="data">
                        <div v-if="data.row.studio_blood_creation !== null && 
                            data.row.order_id_genetic_file !== null" 
                            class="flex-center">

                            <div class="tag tag-success">
                                <span class="ge--description-xs text-success">
                                    Finalizado
                                </span>
                            </div>
                        </div>
                        <div v-else class="flex-center">
                            <div class="tag tag-warning">
                                <span class="ge--description-xs text-warning">
                                    Analizando
                                </span>
                            </div>
                        </div>
                    </template>

                    <template v-slot:download="data">
                        <button @click="downloadReport360(data.row.patient_id, data.row.order_id)"
                            class="btn btn-outline-light btn-pill"
                            :disabled="data.row.studio_blood_creation === null || 
                            data.row.order_id_genetic_file === null">

                            <span class="ge--description-xs text-dark">
                                <img :src="ico_download" v-if="data.value !== null">
                                Descargar    
                            </span>
                        </button>
                    </template>

                </ge-table>

            </div>
        </div>
    </section>
</template>

<script setup>
    import JWT from 'LIBS/JWT'
    import { ref, reactive, computed, inject } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Images
    const ico_download = ref(require('ASSETS/icons/download'))

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects
    const $sendRequest = inject('$sendRequest')
    const $informativeDate = inject('$informativeDate')

    // Computeds

    // Variables
    const allow_headers = ref([
        // { key: 'order_id', label: "ID" }, // Cristopher comentalo
        // { key: 'patient_id', label: "paciente" },
        { key: 'order_number', label: "Número de orden" }, 
        { key: 'studio_blood_creation', label: "Estudio Sanguíneo" }, 
        { key: 'order_id_genetic_file', label: "Estudio Genético" }, 
        { key: 'creation', label: "Fecha de orden" }, 
        { key: 'estatus', label: "Estado actual" }, 
        { key: 'download', label: "Reporte G3NOMICS 360" }
    ])
    const data_table = ref([])

    // Methods

    const listOrders = async() => {
        store.dispatch('showLoading')
        let session = await store.dispatch('existSession')
        session = JWT.verify('eac5a137951f', session.session)

        if(session.id == null) {
            store.dispatch('showError', 'Ha ocurrido un error con la sessión')
            store.dispatch('hiddenLoading')
            return
        }

        let query = {
            datatable: true,
            page: 1,
            rows: 10,
            search: '',
            order_by: 'id',
            order_asc: true,
            patient_id: session.id
        }

        let response = await $sendRequest('administrator/order/patient_orders', 'GET', null, query)
        //console.log(response, 'response listOrders')

        if(response.success && response.data.list) {
            data_table.value = response.data.list.result
            store.dispatch('hiddenLoading')
        }
        else if(!response.success && response.errors) {
            store.dispatch('showError', 'Ha ocurrido un error')
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    const downloadPDF = async(data_order, type) => {
        if((type == 'blood' && data_order.studio_blood_creation === null) ||
           (type == 'genetic' && data_order.order_id_genetic_file === null)) {
            return
        }
        let local_order_id = (type == 'genetic') ? data_order.order_id_genetic_file : data_order.order_id

        let url = `${CONFIG.server[CONFIG.env].api}/administrator/bulk/resource?patient_id=${data_order.patient_id}&order_id=${local_order_id}&type=${type}`
        window.open(url, '_blank');
    }

    const downloadReport360 = async(patient_id, order_id) => {
        let url = `${CONFIG.server[CONFIG.env].api}/administrator/bulk/g3nomic?patient_id=${patient_id}&order_id=${order_id}&pdf=${true}`
        window.open(url, '_blank');
    }

    listOrders()

</script>

<style>
    .dashboard {
        height: 100%;
        border-radius: 12px;
    }
</style>